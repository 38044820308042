'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = LinearGradient;
function LinearGradient(stops, x1, y1, x2, y2) {
  this.stops = stops;
  this.x1 = x1;
  this.y1 = y1;
  this.x2 = x2;
  this.y2 = y2;
  this.type = 'LinearGradient';
}