'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Pattern;
function Pattern(url, width, height, left, top) {
  this.url = url;
  this.width = width;
  this.height = height;
  this.left = left;
  this.top = top;
  this.type = 'Pattern';
}