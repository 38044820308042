'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _objectWithoutProperties2 = require('babel-runtime/helpers/objectWithoutProperties');

var _objectWithoutProperties3 = _interopRequireDefault(_objectWithoutProperties2);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _extractProps = require('../utils/extract-props');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var propTypes = {
  opacity: _propTypes2.default.oneOfType([_propTypes2.default.number, _propTypes2.default.string]),
  originX: _propTypes2.default.number,
  originY: _propTypes2.default.number,
  rotation: _propTypes2.default.number,
  scale: _propTypes2.default.number,
  scaleX: _propTypes2.default.number,
  scaleY: _propTypes2.default.number,
  transform: _propTypes2.default.string,
  visible: _propTypes2.default.bool,
  x: _propTypes2.default.number,
  y: _propTypes2.default.number
};

var defaultProps = {
  opacity: undefined,
  originX: undefined,
  originY: undefined,
  rotation: 0,
  scale: 1,
  scaleX: undefined,
  scaleY: undefined,
  transform: undefined,
  visible: undefined,
  x: 0,
  y: 0
};

var Group = function Group(_ref) {
  var opacity = _ref.opacity,
      originX = _ref.originX,
      originY = _ref.originY,
      rotation = _ref.rotation,
      scale = _ref.scale,
      scaleX = _ref.scaleX,
      scaleY = _ref.scaleY,
      transform = _ref.transform,
      visible = _ref.visible,
      x = _ref.x,
      y = _ref.y,
      props = (0, _objectWithoutProperties3.default)(_ref, ['opacity', 'originX', 'originY', 'rotation', 'scale', 'scaleX', 'scaleY', 'transform', 'visible', 'x', 'y']);
  return _react2.default.createElement('g', (0, _extends3.default)({
    opacity: (0, _extractProps.extractOpacity)(visible, opacity),
    transform: (0, _extractProps.extractTransform)(scaleX != null ? scaleX : scale, scaleY != null ? scaleY : scale, x, y, rotation, originX, originY, transform)
  }, props));
};

Group.propTypes = process.env.NODE_ENV !== "production" ? propTypes : {};
Group.defaultProps = defaultProps;

exports.default = Group;