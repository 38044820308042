'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = RadialGradient;
function RadialGradient(stops, fx, fy, rx, ry, cx, cy) {
  this.stops = stops;
  this.fx = fx;
  this.fy = fy;
  this.rx = rx;
  this.ry = ry;
  this.cx = cx;
  this.cy = cy;
  this.type = 'RadialGradient';
}