'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _objectWithoutProperties2 = require('babel-runtime/helpers/objectWithoutProperties');

var _objectWithoutProperties3 = _interopRequireDefault(_objectWithoutProperties2);

var _keys = require('babel-runtime/core-js/object/keys');

var _keys2 = _interopRequireDefault(_keys);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _isObject = require('lodash/isObject');

var _isObject2 = _interopRequireDefault(_isObject);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _uuid = require('uuid');

var _uuid2 = _interopRequireDefault(_uuid);

var _extractProps = require('../utils/extract-props');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var propTypes = {
  d: _propTypes2.default.string,
  fill: _propTypes2.default.oneOfType([_propTypes2.default.object, _propTypes2.default.string]).isRequired,
  height: _propTypes2.default.number,
  opacity: _propTypes2.default.oneOfType([_propTypes2.default.number, _propTypes2.default.string]),
  originX: _propTypes2.default.number,
  originY: _propTypes2.default.number,
  rotation: _propTypes2.default.number,
  scale: _propTypes2.default.number,
  scaleX: _propTypes2.default.number,
  scaleY: _propTypes2.default.number,
  stroke: _propTypes2.default.string,
  strokeCap: _propTypes2.default.string,
  strokeDash: _propTypes2.default.array,
  strokeJoin: _propTypes2.default.string,
  strokeWidth: _propTypes2.default.number,
  transform: _propTypes2.default.object,
  visible: _propTypes2.default.bool,
  width: _propTypes2.default.number,
  x: _propTypes2.default.number,
  y: _propTypes2.default.number
};

var defaultProps = {
  d: undefined,
  fill: 'none',
  height: undefined,
  opacity: undefined,
  originX: undefined,
  originY: undefined,
  rotation: 0,
  scale: 1,
  scaleX: undefined,
  scaleY: undefined,
  stroke: undefined,
  strokeCap: 'round',
  strokeDash: undefined,
  strokeJoin: 'round',
  strokeWidth: 1,
  transform: undefined,
  visible: undefined,
  width: undefined,
  x: 0,
  y: 0
};

var Shape = function (_React$PureComponent) {
  (0, _inherits3.default)(Shape, _React$PureComponent);

  function Shape(props) {
    (0, _classCallCheck3.default)(this, Shape);

    var _this = (0, _possibleConstructorReturn3.default)(this, (Shape.__proto__ || (0, _getPrototypeOf2.default)(Shape)).call(this, props));

    _this.id = _uuid2.default.v1();
    return _this;
  }

  (0, _createClass3.default)(Shape, [{
    key: 'getCoordinates',
    value: function getCoordinates(isAllArgs, fill) {
      if (isAllArgs) {
        return fill;
      }
      var angle = (fill.x1 == null ? 270 : fill.x1) * Math.PI / 180;
      var x = Math.cos(angle);
      var y = -Math.sin(angle);
      var l = (Math.abs(x) + Math.abs(y)) / 2;
      var newX = x * l;
      var newY = y * l;
      return {
        x1: 0.5 - newX,
        x2: 0.5 + newX,
        y1: 0.5 - newY,
        y2: 0.5 + newY
      };
    }
  }, {
    key: 'renderLinearGradient',
    value: function renderLinearGradient(fill) {
      var isAllArgs = fill.stops != null && fill.x1 != null && fill.y1 != null && fill.x2 != null && fill.y2 != null;
      var coordinates = this.getCoordinates(isAllArgs, fill);
      return _react2.default.createElement(
        'linearGradient',
        {
          id: this.id,
          spreadMethod: 'reflect',
          gradientUnits: isAllArgs ? 'userSpaceOnUse' : undefined,
          x1: coordinates.x1,
          y1: coordinates.y1,
          x2: coordinates.x2,
          y2: coordinates.y2
        },
        (0, _keys2.default)(fill.stops).map(function (stop) {
          return _react2.default.createElement('stop', { key: stop, offset: stop, stopColor: fill.stops[stop], stopOpacity: '1' });
        })
      );
    }
  }, {
    key: 'renderPattern',
    value: function renderPattern(fill) {
      return _react2.default.createElement(
        'pattern',
        {
          id: this.id,
          patternUnits: 'userSpaceOnUse',
          x: fill.left || 0,
          y: fill.top || 0,
          width: fill.width,
          height: fill.height
        },
        _react2.default.createElement('image', { xlinkHref: fill.url, width: fill.width, height: fill.height, preserveAspectRatio: 'none' })
      );
    }
  }, {
    key: 'renderRadialGradient',
    value: function renderRadialGradient(fill) {
      var _props = this.props,
          height = _props.height,
          width = _props.width,
          x = _props.x,
          y = _props.y;

      var focusX = fill.fx == null ? (x || 0) + (width || 0) * 0.5 : fill.fx;
      var focusY = fill.fy == null ? (y || 0) + (height || 0) * 0.5 : fill.fy;
      var radiusY = fill.ry == null ? fill.ry || height * 0.5 || 0 : fill.ry;
      var radiusX = fill.rx == null ? (width || 0) * 0.5 : fill.rx;
      var centerX = fill.cx == null ? focusX : fill.cx;
      var centerY = fill.cy == null ? focusY : fill.cy;
      var ys = radiusY / radiusX;
      return _react2.default.createElement(
        'radialGradient',
        {
          id: this.id,
          gradientUnits: 'userSpaceOnUse',
          gradientTransform: ys != 1 ? 'scale(1, ' + ys + ')' : undefined,
          spreadMethod: 'reflect',
          fx: focusX,
          fy: focusY / ys,
          r: radiusX,
          cx: centerX,
          cy: centerY / ys
        },
        (0, _keys2.default)(fill.stops).map(function (stop) {
          return _react2.default.createElement('stop', { key: stop, offset: stop, stopColor: fill.stops[stop], stopOpacity: '1' });
        })
      );
    }
  }, {
    key: 'renderFill',
    value: function renderFill(fill) {
      if (fill.type === 'LinearGradient') {
        return this.renderLinearGradient(fill);
      }
      if (fill.type === 'Pattern') {
        return this.renderPattern(fill);
      }
      if (fill.type === 'RadialGradient') {
        return this.renderRadialGradient(fill);
      }
      return fill;
    }
  }, {
    key: 'render',
    value: function render() {
      var _props2 = this.props,
          d = _props2.d,
          fill = _props2.fill,
          opacity = _props2.opacity,
          originX = _props2.originX,
          originY = _props2.originY,
          rotation = _props2.rotation,
          scale = _props2.scale,
          scaleX = _props2.scaleX,
          scaleY = _props2.scaleY,
          stroke = _props2.stroke,
          strokeCap = _props2.strokeCap,
          strokeDash = _props2.strokeDash,
          strokeJoin = _props2.strokeJoin,
          strokeWidth = _props2.strokeWidth,
          transform = _props2.transform,
          visible = _props2.visible,
          x = _props2.x,
          y = _props2.y,
          props = (0, _objectWithoutProperties3.default)(_props2, ['d', 'fill', 'opacity', 'originX', 'originY', 'rotation', 'scale', 'scaleX', 'scaleY', 'stroke', 'strokeCap', 'strokeDash', 'strokeJoin', 'strokeWidth', 'transform', 'visible', 'x', 'y']);

      if (!(0, _isObject2.default)(fill)) {
        return _react2.default.createElement('path', (0, _extends3.default)({
          d: d,
          fill: fill,
          fillRule: 'evenodd',
          opacity: (0, _extractProps.extractOpacity)(visible, opacity),
          stroke: stroke,
          strokeDasharray: strokeDash ? strokeDash.join(',') : undefined,
          strokeLinecap: strokeCap,
          strokeLinejoin: strokeJoin,
          strokeWidth: String(strokeWidth),
          transform: (0, _extractProps.extractTransform)(scaleX != null ? scaleX : scale, scaleY != null ? scaleY : scale, x, y, rotation, originX, originY, transform)
        }, props));
      }
      return _react2.default.createElement(
        'g',
        null,
        _react2.default.createElement(
          'defs',
          null,
          this.renderFill(fill)
        ),
        _react2.default.createElement('path', (0, _extends3.default)({
          d: d,
          fill: 'url(#' + this.id + ')',
          fillRule: 'evenodd',
          opacity: (0, _extractProps.extractOpacity)(visible, opacity),
          stroke: stroke,
          strokeDasharray: strokeDash ? strokeDash.join(',') : undefined,
          strokeLinecap: strokeCap,
          strokeLinejoin: strokeJoin,
          strokeWidth: String(strokeWidth),
          transform: (0, _extractProps.extractTransform)(scaleX != null ? scaleX : scale, scaleY != null ? scaleY : scale, x, y, rotation, originX, originY, transform)
        }, props))
      );
    }
  }]);
  return Shape;
}(_react2.default.PureComponent);

exports.default = Shape;


Shape.propTypes = process.env.NODE_ENV !== "production" ? propTypes : {};
Shape.defaultProps = defaultProps;