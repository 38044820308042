'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _reactArtSvgRenderer = require('react-art-svg-renderer');

var _reactArtSvgRenderer2 = _interopRequireDefault(_reactArtSvgRenderer);

var _index = require('./index.native-and-web');

var _index2 = _interopRequireDefault(_index);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = (0, _index2.default)(_reactArtSvgRenderer2.default.Surface);