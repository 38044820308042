'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _objectWithoutProperties2 = require('babel-runtime/helpers/objectWithoutProperties');

var _objectWithoutProperties3 = _interopRequireDefault(_objectWithoutProperties2);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var propTypes = {
  height: _propTypes2.default.number,
  style: _propTypes2.default.object,
  width: _propTypes2.default.number
};

var defaultProps = {
  height: 0,
  style: {},
  width: 0
};

var Surface = function Surface(_ref) {
  var height = _ref.height,
      style = _ref.style,
      width = _ref.width,
      props = (0, _objectWithoutProperties3.default)(_ref, ['height', 'style', 'width']);
  return _react2.default.createElement('svg', (0, _extends3.default)({}, props, { style: (0, _extends3.default)({}, style, { height: height, width: width }) }));
};

Surface.propTypes = process.env.NODE_ENV !== "production" ? propTypes : {};
Surface.defaultProps = defaultProps;

exports.default = Surface;