'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.extractOpacity = extractOpacity;
exports.extractTransform = extractTransform;

var _transform = require('art/core/transform');

var _transform2 = _interopRequireDefault(_transform);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function extractOpacity(visible, opacity) {
  if (visible === false) {
    return 0;
  }
  if (opacity == null) {
    return 1;
  }
  return opacity;
}

function extractTransform(scaleX, scaleY, x, y, rotation, originX, originY, transform) {
  var pooledTransform = new _transform2.default();
  pooledTransform.transformTo(1, 0, 0, 1, 0, 0).move(x, y).rotate(rotation, originX, originY).scale(scaleX, scaleY, originX, originY);
  if (transform != null) {
    pooledTransform.transform(transform);
  }
  return 'matrix(' + [pooledTransform.xx, pooledTransform.yx, pooledTransform.xy, pooledTransform.yy, pooledTransform.x, pooledTransform.y] + ')';
}